import React, {FunctionComponent, ReactNode} from "react"
import {Container, Menu} from "semantic-ui-react"
import {useHistory, useLocation} from "react-router-dom"
import {Paths} from "../../util/Paths"
import {navLinks} from "./Footer"
import {imgixAssetFilename} from "../../util/util"
import Imgix from "react-imgix"

interface NavProps {
  children: ReactNode
}

const DesktopNav: FunctionComponent<NavProps> = ({children}) => {

  const history = useHistory()
  const location = useLocation()

  return (
    <>
      <div className='cg nav'>
        <Container className='cg nav'>
          <Menu borderless={true} stackable={true}>
            <Menu.Item className='logo' onClick={() => history.push(Paths.home())}>
              <Imgix className='ui' src={imgixAssetFilename('logo.png')} htmlAttributes={{alt: 'Cryptograms'}} height={60}/>
            </Menu.Item>
            {navLinks.filter(link => link.path !== Paths.home()).map(link => (
              <Menu.Item key={link.name} onClick={() => history.push(link.path)} active={location.pathname === link.path}>
                <div style={{position: 'relative'}}>
                  <span className='link'>{link.name}</span>
                  <div className='underline' style={{backgroundColor: `${link.color || '#6d6e71'}`, height: '3px', position: 'absolute', bottom: '-10px', width: '100%'}}/>
                </div>
              </Menu.Item>
            ))}
          </Menu>
        </Container>
        <div className='cg nav-break'/>
      </div>
      {children}
    </>
  )
}

export default DesktopNav