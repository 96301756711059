import React, {FunctionComponent} from "react"
import {BrowserRouter as Router, Route, Switch} from "react-router-dom"
import Home from "../home/Home"
import Solve from "../solve/Solve"
import Footer from "../common/Footer"
import {Paths} from "../../util/Paths"
import Colors from "../colors/Colors"
import Gallery from "../gallery/Gallery"
import AppContext from "../main/AppContext"
import Check from "../check/Check"
import Item from "./Item"
import PrivacyPolicy from "./Privacy"
import Terms from "./Terms"
import Nav from "../common/Nav"

const AppRouter: FunctionComponent = () => {

  return (
    <Router>
      <AppContext/>
      <Nav>
        <Switch>
          <Route exact path="/"><Home /></Route>
          <Route path={Paths.gallery()}><Gallery/></Route>
          <Route path={Paths.colors()}><Colors/></Route>
          <Route path={Paths.check()}><Check/></Route>
          <Route path={Paths.solve()}><Solve/></Route>
          <Route path={'/:id(\\d{1,2})'}><Item/></Route>
          <Route path={Paths.privacy()}><PrivacyPolicy/></Route>
          <Route path={Paths.terms()}><Terms/></Route>
        </Switch>
        <Footer/>
      </Nav>
    </Router>
  )
}

export default AppRouter