import React, {FunctionComponent} from "react"
import {Link} from "react-router-dom"
import {Paths} from "../../util/Paths"
import Imgix from "react-imgix"
import {imgixAssetFilename} from "../../util/util"

interface NavLink {
  name: string
  path: string
  color?: string
}

export const navLinks: Array<NavLink> = [
  {name: 'Home', path: Paths.home()},
  {name: 'Gallery', path: Paths.gallery(), color: '#ed9122'},
  // {name: 'Purchase', path: Paths.colors()},
  {name: 'Colors', path: Paths.colors(), color: '#fcc60e'},
  {name: 'Verify', path: Paths.check(), color: '#5bba47'},
  {name: 'Solve', path: Paths.solve(), color: '#a84499'},
]

const Footer: FunctionComponent = () => {

  const onLinkClick = () => window.scrollTo(0, 0)


  return (
    <div className={'cg footer'}>
      <div className={'row'}>
        <Imgix className={'logo'} src={imgixAssetFilename('logo-inverted.png')}
               htmlAttributes={{alt: 'Cryptograms Logo'}}/>
      </div>
      {navLinks && navLinks.length > 0 &&
      <div className={'row'}>
        {navLinks.map((link, i) => (<span key={link.name}>{i > 0 && ` | `}<Link to={link.path} onClick={onLinkClick}>{link.name}</Link></span>))}
      </div>
      }
      <div className={'row'}>
        <Link to={Paths.privacy()} onClick={onLinkClick}>Privacy Policy</Link> | <Link to={Paths.terms()} onClick={onLinkClick}>Terms and Conditions</Link>
      </div>
      <div className={'row'}>
        <a href='https://twitter.com/cryptograms_nft' target='_twitter' style={{display: 'inline'}}>
          <Imgix src={imgixAssetFilename('twitter-white.png')} htmlAttributes={{alt: 'Twitter'}}
                 width={30}/>
        </a>
      </div>
    </div>
  )
}

export default Footer