import React, {ReactNode, useEffect, useState} from "react"
import {WS_HOST} from "../variables"
import Web3 from "web3"
import {ABI, CONTRACT_ADDRESS, ITEM_COUNT} from "../variables"
import moment from "moment"

const web3 = new Web3(WS_HOST)
const cg = new web3.eth.Contract(ABI as any, CONTRACT_ADDRESS)

interface UseClaimed {
  isClaimed?: boolean
  address?: string
  date?: string
  isClaimedText?: ReactNode
}

const useClaimed = (): UseClaimed => {

  const [isClaimed, setClaimed] = useState<boolean>()
  const [address, setAddress] = useState<string>()
  const [date, setDate] = useState<string>()
  const isClaimedText = isClaimed && (
    <span>
      Cryptogram #{ITEM_COUNT} was solved and claimed by<br/>
      {(address && address) || 'unknown'}<br/>
      {date && ` on ${moment(parseInt(date)*1000).format('MMMM D, YYYY [at] h:mma')}`}.
    </span>
  )

  useEffect(() => {
    cg.methods.isClaimed(ITEM_COUNT).call()
      .then((result: any) => {
        setClaimed(result)
      })
      .catch((err: any) => {
        console.error('error checking is claimed', err)
      })

    cg.events.Solved({fromBlock: 0}, function(error: any, event: any) {
      console.debug('received Solved event', event)
      if (event && event.returnValues) {
        console.info('solved token ', event.returnValues.tokenId)
        console.info('solved by ', event.returnValues.to)
        console.info('solved on ', event.returnValues.solvedTimestamp)

        if (event.returnValues.tokenId === `${ITEM_COUNT}`) {
          setClaimed(true)
          setAddress(event.returnValues.to)
          setDate(event.returnValues.solvedTimestamp)
        }
      }
    })
    // })
  }, [])

  return {isClaimed, address, date, isClaimedText}
}

export default useClaimed