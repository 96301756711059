import Abi from "./abi.json"
import {Contract} from "@ethersproject/contracts"
import {Interface} from "ethers/lib/utils"

const required = (s: string | undefined, propertyName: string) => {
  if (!s) {
    throw Error(`${propertyName} is required and not provided`)
  }
  return s
}

const numeric = (s: string | undefined) => (s && parseInt(s)) || undefined

export const BASE_IMGIX_URL = required(process.env.REACT_APP_BASE_IMGIX_URL, 'REACT_APP_BASE_IMGIX_URL')
export const CONTRACT_ADDRESS = required(process.env.REACT_APP_CONTRACT_ADDRESS, 'REACT_APP_CONTRACT_ADDRESS')
export const ITEM_COUNT = numeric(required(process.env.REACT_APP_ITEM_COUNT, 'REACT_APP_ITEM_COUNT'))!
export const CHAIN_ID = numeric(required(process.env.REACT_APP_CHAIN_ID, 'REACT_APP_CHAIN_ID'))
export const CHAIN_URL = required(process.env.REACT_APP_CHAIN_URL, 'REACT_APP_CHAIN_URL')
export const WS_HOST = required(process.env.REACT_APP_WS_HOST, 'REACT_APP_WS_HOST')
export const PURCHASE_LINK = required(process.env.REACT_APP_PURCHASE_LINK, 'REACT_APP_PURCHASE_LINK')
export const AUCTION_START_DATE = numeric(process.env.REACT_APP_AUCTION_START_DATE)
export const AUCTION_END_DATE = numeric(process.env.REACT_APP_AUCTION_END_DATE)

export const ABI = Abi
export const CONTRACT: Contract = new Contract(CONTRACT_ADDRESS, new Interface(ABI))