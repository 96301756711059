import React, {FunctionComponent, useState} from "react"
import {Container, Grid, Header} from "semantic-ui-react"
import {AUCTION_END_DATE, ITEM_COUNT} from "../../variables"
import Lightbox from 'react-image-lightbox'
import 'react-image-lightbox/style.css'
import Imgix from "react-imgix"
import {imgixCryptogramFilename} from "../../util/util" // This only needs to be imported once in your app

interface GalleryProps {
}

interface Item {
  id: number
}

interface Row {
  left: Item
  right?: Item
}

interface ThumbnailProps {
  item: number
  onClick?: () => void
}

export const Thumbnail: FunctionComponent<ThumbnailProps> = ({item, onClick}) => {

  const auctionEnded = !AUCTION_END_DATE || AUCTION_END_DATE < Date.now()

  return (
    <div className='cg thumbnail'>
      <div onClick={onClick} style={{cursor: `${!!onClick && 'pointer'}`}}>
        <Imgix src={imgixCryptogramFilename(`${item}${!auctionEnded && item === ITEM_COUNT ? '-placeholder' : ''}`)} className={'gallery-thumbnail'}/>
        <p style={{textAlign: 'center'}}>#{item}</p>
      </div>
    </div>
  )
}

const Gallery: FunctionComponent<GalleryProps> = () => {

  const [lightboxOpen, setLightboxOpen] = useState<boolean>(false)
  const [index, setIndex] = useState<number>(0)
  const auctionEnded = !AUCTION_END_DATE || AUCTION_END_DATE < Date.now()

  const images = Array(ITEM_COUNT).fill(0)
    .map((v,i) => imgixCryptogramFilename(`${i+1}${!auctionEnded && ITEM_COUNT === i+1 ? '-placeholder' : ''}`))

  let rows: Array<Row> = []
  for (let i = 0; i < ITEM_COUNT; i++) {
    if (i % 2 === 0) {
      rows = rows.concat({left: {id: i+1}})
    } else {
      rows[Math.floor(i/2)].right = {id: i+1}
    }
  }

  const openLightbox = (index: number) => {
    setIndex(index)
    setLightboxOpen(true)
  }

  return (
    <div className='cg gallery'>
      <Container>
        <Header as={'h1'} style={{fontSize: '3em'}}>Gallery</Header>
        {lightboxOpen &&
        <Lightbox
          mainSrc={images[index]}
          nextSrc={images[(index + 1) % images.length]}
          prevSrc={images[(index + images.length - 1) % images.length]}
          onCloseRequest={() => setLightboxOpen(false)}
          onMovePrevRequest={() => setIndex((index + images.length - 1) % images.length)}
          onMoveNextRequest={() => setIndex((index + 1) % images.length)}
          imageCaption={`#${index+1}`}
        />
        }
        <Grid columns={2} stackable={true}>
          {rows.map((row, i) => (
            <Grid.Row key={i}>
              <Grid.Column><Thumbnail key={row.left.id} item={row.left.id} onClick={() => openLightbox(row.left.id-1)}/></Grid.Column>
              {row.right &&
              <Grid.Column><Thumbnail key={row.right.id} item={row.right.id} onClick={() => openLightbox(row.right!.id-1)}/></Grid.Column>
              }
            </Grid.Row>
          ))}
        </Grid>
      </Container>
    </div>
  )
}

export default Gallery