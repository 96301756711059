import React, {FunctionComponent, useState} from "react"
import {Button, Container, Form, Grid, Header, Modal} from "semantic-ui-react"
import {cg, imgixAssetFilename, web3} from "../../util/util"
import Imgix from "react-imgix"
import {AUCTION_END_DATE, ITEM_COUNT} from "../../variables"
import {Thumbnail} from "../gallery/Gallery"
import {toast} from "react-toastify"

interface ResultModalProps {
  open: boolean
  success: boolean
  onClose: () => void
}

const ResultModal: FunctionComponent<ResultModalProps> = ({open, success, onClose}) => {
  return (
    <Modal open={open} closeIcon={true} onClose={onClose} dimmer={'inverted'} size='tiny'>
      <div style={{display: 'flex', justifyContent: 'center', padding: '3em 0'}}>
        <Imgix src={imgixAssetFilename(`thumbs-${success ? 'up' : 'down'}.png`)} width={200} height={200}/>
      </div>
    </Modal>
  )
}

interface CheckProps {

}

const Check: FunctionComponent<CheckProps> = () => {

  const [itemNumber, setItemNumber] = useState<number>(1)
  const [itemNumberError, setItemNumberError] = useState<string>('')
  const [messageText, setMessageText] = useState<string>('')
  const [messageTextError, setMessageTextError] = useState<string>('')
  const [result, setResult] = useState<boolean | null>(null)
  const auctionEnded = !AUCTION_END_DATE || AUCTION_END_DATE < Date.now()
  const items = Array(auctionEnded ? ITEM_COUNT : ITEM_COUNT-1).fill(0).map((v,i) => i+1)

  const handleSubmit = () => {
    console.log('checking', itemNumber, messageText)

    let error = false

    setItemNumberError('')
    if (!itemNumber) {
      setItemNumberError('Required')
      error = true
    }

    setMessageTextError('')
    if (!messageText) {
      setMessageTextError('Required')
      error = true
    }

    if (error) {
      return
    }

    cg.methods.check(itemNumber, web3.utils.keccak256(messageText)).call()
      .then((result: any) => {
        console.log('result', result)
        // setResult(result)
        if (result) {
          toast.success('Correct!')
        } else {
          toast.error('Nope.')
        }
      })
      .catch((err: any) => {
        console.error('error checking solution', err)
      })
  }

  const handleItemNumberChange = (n: number) => {
    setItemNumberError('')
    setItemNumber(n)
  }

  const handleMessageTextChange = (s: string) => {
    setMessageTextError('')
    setMessageText(s && s.toLowerCase())
  }

  const reset = () => {
    setMessageText('')
    setItemNumberError('')
    setMessageTextError('')
  }

  return (
    <div className='cg check'>
      <ResultModal open={result !== null} success={!!result} onClose={() => setResult(null)}/>
      <Container>
        <Grid columns={2} stackable={true}>
          <Grid.Row>
            <Grid.Column>
              <div style={{backgroundColor: '#f1f1f1', padding: '2em', borderRadius: '6px'}}>
                <Header as={'h1'} style={{fontSize: '2em'}}>Verify</Header>
                <p>Use this verification tool to check the decoded message for any cryptogram. All answers are verified
                directly against the smart contract.</p>
                <Form onSubmit={handleSubmit}>
                  <Form.Dropdown value={itemNumber} selection={true} label={'Cryptogram #'} error={itemNumberError || null}
                                 onChange={(e,data) => handleItemNumberChange(data.value as number)}
                                 options={items.map(i => ({key: i, text: i, value: i}))}/>
                  <Form.Input placeholder={'Decoded message'} value={messageText} label={'Message Text'}
                              error={messageTextError || null}
                              onChange={(e,data) => handleMessageTextChange(data.value)}/>
                  <Button primary={true} type='submit'>Check</Button>
                  <Button basic={true} type='button' onClick={reset}>Reset</Button>
                </Form>
              </div>
            </Grid.Column>
            <Grid.Column>
              {itemNumber &&
              <Thumbnail item={itemNumber}/>
              }
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Container>
    </div>
  )
}

export default Check