import React, {FunctionComponent} from "react"
import {Container} from "semantic-ui-react"
import {imgixAssetFilename} from "../../util/util"
import {Background} from "react-imgix"

const DetailsBanner: FunctionComponent = () => {

  return (
    <Background className='cg details-banner' src={imgixAssetFilename('cipher.png')}>
      <Container textAlign={'center'} style={{width: '700px'}}>
        <h2 color={'white'} style={{fontSize: '2em', fontStyle: 'italic'}}>The Cipher</h2>
        <p>
          Each of the 50 cryptograms has a message encoded in the art. All of the messages are encoded with
          the same cipher, so once you solve it you’ll be able to decode each of the messages.
          Can you unlock the cipher and decode them all?
        </p>
        <h2 color={'white'} style={{fontSize: '2em', fontStyle: 'italic'}}>Smart Contract</h2>
        <p>
          Cryptograms is an ERC-721 smart contract on the Ethereum blockchain.
        </p>
      </Container>
    </Background>
  )
}

export default DetailsBanner