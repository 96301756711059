import React, {FunctionComponent} from 'react'
import '../../App.css'
import WelcomeBanner from "./WelcomeBanner"
import MysteryBanner from "./MysteryBanner"
import DetailsBanner from "./DetailsBanner"

const Home: FunctionComponent = () => {

  return (
    <div>
      <WelcomeBanner/>
      <DetailsBanner/>
      <MysteryBanner/>
    </div>
  );
}

export default Home