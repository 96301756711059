import React, {FunctionComponent} from "react"
import {useParams} from "react-router-dom"
import {Thumbnail} from "../gallery/Gallery"
import {Container, Header} from "semantic-ui-react"

const Item: FunctionComponent = () => {

  const {id} = useParams() as any

  return (
    <div className={'cg item'}>
      <Container>
        <Header as={'h1'} style={{fontSize: '3em', textAlign: 'center'}}>Cryptogram #{id}</Header>
        <Thumbnail item={parseInt(id)}/>
      </Container>
    </div>
  )
}

export default Item