import React, {FunctionComponent, useEffect, useState} from "react"
import {AUCTION_END_DATE, CONTRACT, ITEM_COUNT, PURCHASE_LINK} from "../../variables"
import {Button, Container, Form, Grid, InputOnChangeData, Loader, Message} from "semantic-ui-react"
import {useContractFunction, useEtherBalance, useEthers} from "@usedapp/core"
import {formatEther} from "ethers/lib/utils"
import useClaimed from "../../hooks/useClaimed"
import {web3} from "../../util/util"
import {Thumbnail} from "../gallery/Gallery"
import moment from "moment"
import CountdownClock from "../common/CountdownClock"
import {toast} from "react-toastify"

let messageCount = 0

interface MessageData {
  key: number
  error?: boolean
  info?: boolean
  success?: boolean
  text: string
}

interface MessagesProps {
  messages: MessageData[]
  onDismiss: (key: number) => void
}

const Messages: FunctionComponent<MessagesProps> = ({messages, onDismiss}) => {
  return (
    <>
      {messages.map(message => (
        <Message key={message.key} success={message.success} info={message.info} error={message.error}
                 content={message.text} onDismiss={() => onDismiss(message.key)}/>
      ))}
    </>
  )
}

const Solve: FunctionComponent = () => {

  const {activateBrowserWallet, account, deactivate } = useEthers()
  const etherBalance = useEtherBalance(account)

  const { state, send } = useContractFunction(CONTRACT as any, 'claim')

  const [solutionText, setSolutionText] = useState<string>('')
  const [solutionTextError, setSolutionTextError] = useState<string | null>(null)
  const {isClaimed, isClaimedText} = useClaimed()
  const released = !AUCTION_END_DATE || AUCTION_END_DATE < Date.now()
  const [messages, setMessages] = useState<MessageData[]>([])

  const connect = () => {
    activateBrowserWallet()
  }

  const pushMessage = (m: MessageData) => {
    setMessages(prev => [m].concat(prev))
  }

  useEffect(() => {
    console.log('state', state)
    if (state && state.errorMessage && state.errorMessage.includes('incorrect message text')) {
      const msg = 'The message text you entered is not correct.'
      toast.error(msg)
      // pushMessage(({key: messageCount++, error: true, text: 'The message text you entered is not correct.'}))
    } else if (state && state.status === 'Exception') {
      const msg = `An unexpected error occurred: ${state && state.errorMessage}`
      pushMessage(({key: messageCount++, error: true, text: msg}))
    } else if (state && state.status === 'Mining') {
      const hash = (state && state.transaction && state.transaction.hash) || 'unknown'
      const msg = `Your transaction is processing. Transaction hash: ${hash}`
      pushMessage({key: messageCount++, info: true, text: msg})
    } else if (state && state.status === 'Success') {
      const msg = `Your transaction was successful. Congratulations! You are the owner of Cryptogram #${ITEM_COUNT}.`
      pushMessage({key: messageCount++, success: true, text: msg})
    } else if (state && state.status === 'Fail') {
      const msg = `Your transaction failed: ${state && state.errorMessage}`
      pushMessage({key: messageCount++, error: true, text: msg})
    }
  }, [state])

  const solve = () => {
    console.log(`solving with ${solutionText}`)

    setSolutionTextError(null)

    if (!solutionText) {
      setSolutionTextError('Required')
      return
    }

    send(ITEM_COUNT, web3.utils.keccak256(solutionText))
      .catch(err => {
        console.error('error claiming', err, state)
        toast.error('An unknown error occurred. Check console for more details. Please try again.', {autoClose: false})
      })
  }

  const onChangeMessageText = (e: any, data: InputOnChangeData) => {
    setSolutionText(data.value && data.value.toLowerCase())
    setSolutionTextError(null)
  }

  const handleMessageDismiss = (key: number) => {
    setMessages(prev => prev.filter(m => m.key !== key))
  }

  return (
    <div className={'cg solve'}>
      <Container>
        <Grid columns={2} stackable={true}>
          <Grid.Row>
            <Grid.Column>
              <Thumbnail item={ITEM_COUNT}/>
            </Grid.Column>
            <Grid.Column>
              <div className={'info-container'}>
                <div className={'info'}>
                  <h2 style={{fontSize: '6em', color: '#354049'}}>#{ITEM_COUNT}</h2>
                  {isClaimed === undefined &&
                  <Loader/>
                  }
                  <p className={'subtext'}>
                    This cryptogram is different. It will be awarded to the first person to solve it. Want it?
                    Just be the first person to decode the message and it's yours.
                  </p>
                  {released && isClaimed &&
                  <div style={{backgroundColor: '#f1f1f1', padding: '2em', borderRadius: '6px'}}>
                    <Messages messages={messages} onDismiss={handleMessageDismiss}/>
                    <p className='subtext'>{isClaimedText}</p>
                  </div>
                  }
                  {!released &&
                  <div style={{backgroundColor: '#f1f1f1', padding: '1em', borderRadius: '6px', textAlign: 'center'}}>
                    <p className={'subtext'}>
                      It will be dropped at the end of
                      the <a href={PURCHASE_LINK} target={'_purchase'}>OpenSea auction</a> on {moment(AUCTION_END_DATE).format('MMMM D [at] h:mma')}.
                    </p>
                    <CountdownClock date={AUCTION_END_DATE} align='center'/>
                  </div>
                  }
                  {released && isClaimed === false && !account &&
                  <div style={{backgroundColor: '#f1f1f1', padding: '2em', borderRadius: '6px'}}>
                    <p className='subtext'>
                      To solve and claim #{ITEM_COUNT}, start by connecting a web-enabled wallet like MetaMask.
                    </p>
                    <Button onClick={connect} primary={true}>Connect Wallet</Button>
                  </div>
                  }
                  {released && isClaimed === false && account &&
                  <div style={{backgroundColor: '#f1f1f1', padding: '2em', borderRadius: '6px'}}>
                    <Messages messages={messages} onDismiss={handleMessageDismiss}/>
                    <p style={{marginBottom: 0}}>You're connected.</p>
                    <p style={{marginBottom: 0}}>Account: {account}</p>
                    <p>Balance: {etherBalance && formatEther(etherBalance)} ETH</p>
                    <p>Enter the decoded message text below to solve and claim ownership of this token.</p>
                    <Form onSubmit={solve}>
                      <Form.Input value={solutionText} placeholder='Message' error={solutionTextError}
                                  onChange={onChangeMessageText}/>
                      <Button primary={true} type={'submit'}>Solve</Button>
                      <Button onClick={deactivate} basic={true}>Disconnect</Button>
                    </Form>
                  </div>
                  }
                </div>
              </div>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Container>
    </div>
  )
}

export default Solve