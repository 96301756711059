import React, {FunctionComponent} from "react"
import Countdown, {CountdownProps} from "react-countdown"
import {Property} from "csstype"

interface ClockItemProps {
  value: number
  label: string
}

const ClockItem: FunctionComponent<ClockItemProps> = ({value, label}) => {
  return (
    <div className='cg clock-item'>
      <div className='value'>{value}</div>
      <div className='label'>{label}</div>
    </div>
  )
}

interface CountdownClockProps extends CountdownProps {
  align?: Property.TextAlign
}

const CountdownClock: FunctionComponent<CountdownClockProps> = (props) => {

  const align = props.align

  return <Countdown {...props} renderer={({days, hours, minutes, seconds}) => (
    <div style={{textAlign: align || 'left'}}>
      <ClockItem value={days} label={'days'}/>
      <ClockItem value={hours} label={'hrs'}/>
      <ClockItem value={minutes} label={'mins'}/>
      <ClockItem value={seconds} label={'secs'}/>
    </div>
  )}/>
}

export default CountdownClock