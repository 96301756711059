import React, {FunctionComponent} from "react"
import {Container, Grid, Header} from "semantic-ui-react"

interface ColorsProps {

}

interface ColorData {
  hex: string
  name: string
  light?: boolean
}

const colors: Array<ColorData> = [
  {hex: '#0f3c60', name: 'midnight moon'},
  {hex: '#13a557', name: 'saint patty\'s day'},
  {hex: '#253c93', name: 'burple'},
  {hex: '#2d75b5', name: 'it\'s a boy'},
  {hex: '#354049', name: 'coal'},
  {hex: '#5bba47', name: 'april morning'},
  {hex: '#6d6e71', name: 'tarnished silver'},
  {hex: '#77d2f7', name: 'ciel', light: true},
  {hex: '#7b439a', name: 'lavender field'},
  {hex: '#8a5838', name: 'mud'},
  {hex: '#9b89b8', name: 'dried violet'},
  {hex: '#a81d4b', name: 'pom'},
  {hex: '#a84499', name: 'brilliant'},
  {hex: '#b52c74', name: 'kiss me'},
  {hex: '#bdd131', name: 'sour margarita', light: true},
  {hex: '#bf8552', name: 'sierra'},
  {hex: '#c08497', name: 'calm'},
  {hex: '#e26b2c', name: 'pumpkin pie'},
  {hex: '#e43526', name: 'hot mess'},
  {hex: '#ead2ac', name: 'sahara', light: true},
  {hex: '#ed9122', name: 'juice'},
  {hex: '#f05d8e', name: 'bubble yum'},
  {hex: '#fac9d4', name: 'camellia', light: true},
  {hex: '#faf185', name: 'meringue', light: true},
  {hex: '#fcc60e', name: 'corn maize', light: true},
  {hex: '#fde441', name: 'morning light', light: true},
]

interface SectionProps {
  colors: ColorData[]
}

const Section: FunctionComponent<SectionProps> = ({colors}) => {
  return (
    <>
      {colors.map(color => (
        <div key={color.hex} className='color' style={{backgroundColor: color.hex, color: color.light ? 'black' : 'white'}}>
          <span>{color.name}</span>
          <span>{color.hex}</span>
        </div>
      ))}
    </>
  )
}

const Colors: FunctionComponent<ColorsProps> = () => {

  return (
    <div className='cg colors'>
      <Container>
        <Header as={'h1'} style={{fontSize: '3em'}}>Colors</Header>
        <p style={{fontSize: '1.2em'}}>
          The cryptogram color palette,
          in alphabetical order by hex code so as not to discriminate.
        </p>
        <Grid columns={2} stackable={true}>
          <Grid.Row>
            <Grid.Column>
              <Section colors={colors.slice(0,13)}/>
            </Grid.Column>
            <Grid.Column>
              <Section colors={colors.slice(13,26)}/>
            </Grid.Column>
          </Grid.Row>
        </Grid>
        {/*{colors.map(color => (*/}
        {/*  <div className='color'>*/}
        {/*    <div className='hex' style={{backgroundColor: color.hex}}/>*/}
        {/*    <span>{color.name}</span>*/}
        {/*  </div>*/}
        {/*))}*/}
      </Container>
    </div>
  )
}

export default Colors