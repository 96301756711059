import {FunctionComponent, useEffect} from "react"
import {useLocation} from "react-router-dom"

declare const window: Window &
  typeof globalThis & {
  gtag: any
}

const AppContext: FunctionComponent = () => {

  const location = useLocation()

  useEffect(() => {
    window.gtag('event', 'page_view', {
      page_title: location.pathname,
      page_location: window.location.href,
      page_path: location.pathname
    });
  }, [location && location.pathname])

  return null
}

export default AppContext