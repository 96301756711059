import React, {FunctionComponent, ReactNode, useEffect, useState} from "react"
import DesktopNav from "./DesktopNav"
import MobileNav from "./MobileNav"

interface NavProps {
  children: ReactNode
}

const Nav: FunctionComponent<NavProps> = ({children}) => {

  const [width, setWidth] = useState<number>(window.innerWidth)

  const handleResize = () => setWidth(window.innerWidth)

  useEffect(() => {
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  })

  if (width < 775) {
    return <MobileNav>{children}</MobileNav>
  } else {
    return <DesktopNav>{children}</DesktopNav>
  }
}

export default Nav