import React from 'react'
import './App.css'
import 'semantic-ui-css/semantic.min.css'
import AppRouter from "./components/main/AppRouter"
import {ChainId, DAppProvider} from "@usedapp/core"
import {ToastContainer} from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import {CHAIN_ID, CHAIN_URL} from "./variables"

const config = {
  readOnlyChainId: CHAIN_ID,
  readOnlyUrls: {
    [CHAIN_ID!]: CHAIN_URL,
  },
  supportedChains: [
    ChainId.Mainnet,
    ChainId.Rinkeby,
    ChainId.Localhost,
  ],
}

function App() {

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme={"colored"}
      />
      <DAppProvider config={config}>
        <AppRouter/>
      </DAppProvider>
    </>
  );
}

export default App;
