import React, {FunctionComponent} from "react"
import {Button, Container, Grid, Icon} from "semantic-ui-react"
import AutoplayGallery from "../common/AutoplayGallery"
import {AUCTION_START_DATE, ITEM_COUNT, PURCHASE_LINK} from "../../variables"
import {Paths} from "../../util/Paths"
import {useHistory} from "react-router-dom"
import moment from "moment"
import CountdownClock from "../common/CountdownClock"

interface WelcomeBannerProps {
}

const WelcomeBanner: FunctionComponent<WelcomeBannerProps> = () => {

  const history = useHistory()
  const auctionPending = AUCTION_START_DATE && AUCTION_START_DATE > Date.now()

  const navToSolve = () => {
    window.scrollTo(0, 0)
    history.push(Paths.solve())
  }

  return (
    <div className={'cg welcome-banner'}>
      <Container>
        <Grid columns={2} stackable={true}>
          <Grid.Row>
            <Grid.Column>
              <div className={'info-container'}>
                <div className={'info'}>
                  <h2 style={{fontSize: '3.5em', fontStyle: 'italic', color: '#354049'}}>An Original NFT Collection<br/>of Encoded Art</h2>
                  <p className='subtext'>
                    Fifty unique cryptograms, each with its own encoded message.
                    All but the last one can be <a href={PURCHASE_LINK} target={'_purchase'}>purchased on OpenSea</a>.
                    We’re giving away #50 to the first person who can decode it.
                  </p>
                  {auctionPending &&
                  <div>
                    <div style={{backgroundColor: '#f1f1f1', padding: '1em', borderRadius: '6px'}}>
                      <p className='subtext' style={{textAlign: 'center', marginBottom: '.5em'}}>
                        Drop begins {moment(AUCTION_START_DATE).format('MMMM D [at] h:mma')}.
                      </p>
                      <CountdownClock date={AUCTION_START_DATE} align='center'/>
                      <div style={{textAlign: 'center', marginTop: '1em'}}>
                        <Button primary={true} as='a' href={PURCHASE_LINK} target='_purchase'>Purchase&nbsp;&nbsp;&nbsp;&nbsp;<Icon name='external alternate'/></Button>
                      </div>
                    </div>
                  </div>
                  }
                  {!auctionPending &&
                  <>
                    <Button primary={true} as='a' href={PURCHASE_LINK} target='_purchase'>Purchase&nbsp;&nbsp;&nbsp;&nbsp;<Icon name='external alternate'/></Button>
                    <Button basic={true} onClick={navToSolve}>Solve #{ITEM_COUNT}</Button>
                  </>
                  }
                </div>
              </div>
            </Grid.Column>
            <Grid.Column style={{display: 'flex', alignItems: 'center'}}>
              <div className='aspect-container'>
                <div>
                  <AutoplayGallery/>
                </div>
              </div>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Container>
    </div>
  )
}

export default WelcomeBanner