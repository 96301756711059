import React, {FunctionComponent} from "react"
import AwesomeSlider from 'react-awesome-slider'
import 'react-awesome-slider/dist/styles.css'
import withAutoplay from 'react-awesome-slider/dist/autoplay'
import {AUCTION_END_DATE, ITEM_COUNT} from "../../variables"
import {imgixCryptogramFilename} from "../../util/util"

function shuffle(array: any) {
  let currentIndex = array.length, randomIndex;

  // While there remain elements to shuffle...
  while (currentIndex !== 0) {

    // Pick a remaining element...
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;

    // And swap it with the current element.
    [array[currentIndex], array[randomIndex]] = [
      array[randomIndex], array[currentIndex]];
  }

  return array;
}

const AutoplaySlider = withAutoplay(AwesomeSlider)

interface AutoplayGalleryProps {

}

const AutoplayGallery: FunctionComponent<AutoplayGalleryProps> = () => {

  const auctionEnded = !AUCTION_END_DATE || AUCTION_END_DATE < Date.now()

  const items = Array(auctionEnded ? ITEM_COUNT : ITEM_COUNT-1).fill(0).map((v,i) => i+1)
  shuffle(items)

  return (
    <AutoplaySlider
      play={true}
      cancelOnInteraction={false} // should stop playing on user interaction
      interval={6000}
      fillParent={false}
      bullets={false}
      organicArrows={false}
      showTimer={false}
      timerHeight={'0'}
      timerBackgroundColor={'#f1f1f1'}
      loaderBarColor={'#f1f1f1'}
    >
      {items.map(item => (
        <div key={item} data-src={`${imgixCryptogramFilename(item)}?w=550&h=550`} />
      ))}
    </AutoplaySlider>
  )
}

export default AutoplayGallery