import React, {FunctionComponent, ReactNode, useState} from "react"
import {Icon, Menu, Sidebar} from "semantic-ui-react"
import {imgixAssetFilename} from "../../util/util"
import Imgix from "react-imgix"
import {navLinks} from "./Footer"
import {useHistory} from "react-router-dom"

interface MobileNavProps {
  children: ReactNode
}

const MobileNav: FunctionComponent<MobileNavProps> = ({children}) => {

  const [visible, setVisible] = useState<boolean>(false)
  const history = useHistory()

  const onPusherClick = () => {
    if (visible) {
      setVisible(false)
    }
  }

  const navTo = (path: string) => {
    setVisible(false)
    history.push(path)
  }

  return (
    <Sidebar.Pushable>
      <Sidebar
        as={Menu}
        animation="overlay"
        icon="labeled"
        inverted
        vertical
        visible={visible}
        direction={'right'}
      >
        {navLinks.map(link => (
          <Menu.Item key={link.name} onClick={() => navTo(link.path)} style={{cursor: 'pointer', padding: '1.4em 2em'}}>{link.name}</Menu.Item>
        ))}
      </Sidebar>
      <Sidebar.Pusher
        dimmed={visible}
        onClick={onPusherClick}
        style={{ minHeight: "100vh" }}
      >
        <div className='cg nav mobile'>
          <Menu>
            <Menu.Item>
              <Imgix className='ui' src={imgixAssetFilename('logo.png')} htmlAttributes={{alt: 'Cryptograms'}} height={40}/>
            </Menu.Item>
            <Menu.Menu position="right">
              <Menu.Item onClick={() => setVisible(prev => !prev)}>
                <Icon name="sidebar" size='large' style={{color: '#6d6e71'}}/>
              </Menu.Item>
            </Menu.Menu>
          </Menu>
        </div>
        {children}
      </Sidebar.Pusher>
    </Sidebar.Pushable>
  )
}

export default MobileNav