import React, {FunctionComponent} from "react"
import {useHistory} from "react-router-dom"
import {Button, Container, Grid} from "semantic-ui-react"
import {AUCTION_END_DATE, AUCTION_START_DATE, ITEM_COUNT, PURCHASE_LINK} from "../../variables"
import {Paths} from "../../util/Paths"
import useClaimed from "../../hooks/useClaimed"
import moment from "moment"
import {Thumbnail} from "../gallery/Gallery"
import CountdownClock from "../common/CountdownClock"

interface MysteryBannerProps {
}

const MysteryBanner: FunctionComponent<MysteryBannerProps> = () => {

  const history = useHistory()
  const auctionPending = AUCTION_START_DATE && AUCTION_START_DATE > Date.now()
  const released = !AUCTION_END_DATE || AUCTION_END_DATE < Date.now()
  const {isClaimed, address, date} = useClaimed()

  const navigateTo = (path: string) => {
    history.push(path)
    window.scrollTo(0, 0)
  }

  return (
    <div className='cg mystery-banner'>
      <Container>
        <Grid columns={2} stackable={true}>
          <Grid.Row>
            <Grid.Column>
              <Thumbnail item={ITEM_COUNT}/>
            </Grid.Column>
            <Grid.Column>
              <div className='info' style={{display: 'flex', alignItems: 'center', height: '100%'}}>
                <div>
                  <h2 style={{fontSize: '6em', color: '#354049'}}>#{ITEM_COUNT}</h2>
                  <p className={'subtext'}>
                    Cryptogram #50 is different. It will be awarded to the first person to solve it. Want it?
                    Just be the first person to decode the message and it's yours.
                  </p>
                  {!released &&
                  <>
                    <p className={'subtext'}>
                      It will be dropped at the end of
                      the <a href={PURCHASE_LINK} target={'_purchase'}>OpenSea auction</a> on {moment(AUCTION_END_DATE).format('MMMM D [at] h:mma')}.
                    </p>
                    {!auctionPending &&
                    <CountdownClock date={AUCTION_END_DATE}/>
                    }
                  </>
                  }
                  {released && isClaimed === true &&
                  <div style={{backgroundColor: '#f1f1f1', padding: '2em', borderRadius: '6px'}}>
                    <p className={'subtext'}>
                      Cryptogram #{ITEM_COUNT} was solved and claimed by<br/>
                      {(address && address) || 'unknown'}<br/>
                      {date && ` on ${moment(parseInt(date)*1000).format('MMMM D, YYYY [at] h:mma')}`}.
                    </p>
                  </div>
                  }
                  {released && !isClaimed &&
                  <Button primary={true} onClick={() => navigateTo(Paths.solve())}>Solve</Button>
                  }
                </div>
              </div>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Container>
    </div>
  )
}

export default MysteryBanner